.time-picker {
  height: 56px;
  margin-top: 16px;
  margin-bottom: 8px;
  width: 52px;
}

.time-picker :first-child {
  border-radius: 4px;
  border-color: #d5d5d5;
}

.time-picker div {
  min-width: unset;
}
